.container1 {
  display: flex;
  flex-direction: row; 
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .container1 {
    flex-direction: column; 
    justify-content: center;
    
    
  }
}