.filter {
  display: flex;
  width: 85%;
  margin: 0 auto;
  justify-content: flex-end;
}

.filter>p:not(:last-child)::after {
  content: "|";
  padding: 10px;
}

.filter p {
  cursor: pointer;
}

.flex_wrap {
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  place-items: baseline;
  gap: 30px 50px;
  margin: 0px auto 0;
  margin-top: 35px;
  max-width: min-content;
}

.flex_wrap2 {
  display: flex;
  place-items: baseline;
  gap: 30px 50px;
  margin: 0px auto 0;
  margin-top: 35px;
  max-width: min-content;
}



/*
  products 
  --------------------------------------------- 
  product
  */

.product {
  display: flex;
  flex-direction: column;
}

.product_image {
  width: 270px;
  height: 270px;
  border-radius: 10px;
  margin-bottom: 5px;
}

.product_image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #cacaca;
}

.store {
  margin-bottom: 5px;
}

.store span {
  font-size: 16px;
  line-height: 22px;
  color: black;
  text-decoration: underline;
  font-weight: bold;
}

.product_name {
  margin-bottom: 0px;
}

.product_name span {
  font-size: 15px;
  line-height: 22px;
  color: #000000;
}

.price {
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  color: #000000;
}

.unit {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.content {
  text-align: center;
}

.contentName1 {
  font-weight: bold;
}

.contentName2 {
  color: #767676;
}

.pagenationImg {
  width: 40px;
  height: 40px;
  margin: auto;
  margin: 0px 5px 5px 5px;
}

.pagenationImg2 {
  width: 8vh;
  font-size: 28px;
  text-align-last: center;
}

.page1 {
  float: left;
}




@media (max-width: 1570px) {
  .product_image {
    width: 200px;
    height: 200px;
  }

  .product_name span {
    font-size: 15px;
  }
}

@media (max-width: 1230px) {
  .product_image {
    width: 180px;
    height: 180px;
  }

  .product_name span {
    font-size: 15px;
  }
}

@media (max-width: 1130px) {
  .product_image {
    width: 150px;
    height: 150px;
  }

  .product_name span {
    font-size: 15px;
  }
}

@media (max-width: 975px) {
  .flex_wrap {
    grid-template-columns: repeat(3, 1fr);
  }

  .product_image {
    width: 180px;
    height: 180px;
  }

  .product_name span {
    font-size: 15px;
  }
}

@media (max-width: 670px) {
  .flex_wrap {
    grid-template-columns: repeat(2, 1fr);
  }

  .product_image {
    width: 180px;
    height: 180px;
  }

  .product_name span {
    font-size: 15px;
  }
}

@media (max-width: 490px) {
  .product_image {
    width: 140px;
    height: 140px;
  }

  .product_name span {
    font-size: 12px;
  }
}